import "../CaseStudy.css";
import React, { useState, useCallback, useEffect } from "react";
import ReactPlayer from "react-player";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList"; // Grid version 2
import ImageListItem from "@mui/material/ImageListItem"; // Grid version 2
import Masonry from "@mui/lab/Masonry";
import Lightbox from "../Components/Lightbox";

import NavBar from "../NavBar";

import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

import { imageKitURL } from "../App";

import { mosaicImages, lightboxImages } from "./2021_36DoT_Images";

import {
  constructionImages,
  lightboxConstruction,
} from "./2021_36DoT_Construction";

import textBalancer from "text-balancer";

function importLetters(r) {
  //// Use these line if you like to access arrays using an integer index.
  let images = [];

  r.keys().map((item, index) => {
    const img = new Image();
    img.src = r(item);
    const filename = r(item).split("/").pop();
    const cleanFilename = filename.split(".").splice(0, 1);
    const ext = filename.split(".").splice(2, 1);
    img.onload = () => {
      images.push({
        src: imageKitURL + "36Days 2021/Letters/" + cleanFilename + "." + ext,
        width: img.width,
        height: img.height,
        alt: "Alt",
      });
    };
  });

  ////Use these line if you want to access each image using the file name.
  //let images = {};
  //r.keys().map((item, index) => { images[item.replace('./','')] = r(item); });

  return images;
}

function importConstruction(r) {
  //// Use these line if you like to access arrays using an integer index.
  let images = [];

  r.keys().map((item, index) => {
    const img = new Image();
    img.src = r(item);
    const filename = r(item).split("/").pop();
    const cleanFilename = filename.split(".").splice(0, 1);
    const ext = filename.split(".").splice(2, 1);
    img.onload = () => {
      images.push({
        src:
          imageKitURL + "36Days 2021/Construction/" + cleanFilename + "." + ext,
        width: img.width,
        height: img.height,
        alt: "Alt",
      });
    };
  });

  ////Use these line if you want to access each image using the file name.
  //let images = {};
  //r.keys().map((item, index) => { images[item.replace('./','')] = r(item); });

  return images;
}

const images = importLetters(
  require.context(
    "../../public/36Days 2021/Letters",
    false,
    /\.(png|jpe?g|gif)$/
  )
);

// const closeups = importLetters(
//   require.context(
//     "../../public/36Days 2021/Closeups",
//     false,
//     /\.(png|jpe?g|gif)$/
//   )
// );

const construction = importConstruction(
  require.context(
    "../../public/36Days 2021/Construction",
    false,
    /\.(png|jpe?g|gif)$/
  )
);

const closeups = [
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Closeups/3-01_01.jpg",
    width: 2161,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Closeups/B-02.jpg",
    width: 2161,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Closeups/J-01.jpg",
    width: 2161,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Closeups/X-01_01.jpg",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
];

function App() {
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  console.log(construction);
  return (
    <div className="App">
      <NavBar />
      <div className="pageWrapper">
        <div className="section">
          <div className="soloCopy">
            <h1>36 Days of Type - 2021</h1>
            <p className="paraCopy">
              For this series I wanted to explore beautiful coloured glass
              materials and subtle imperfections.
            </p>
          </div>
          <div className="fullWidth">
            <img src={imageKitURL + "/36Days 2021/36DoT-2.jpg"} width="100%" />
            {/* <Grid
              container
              rowSpacing={{ xs: 1, md: 2 }}
              columnSpacing={{ xs: 1, md: 2 }}
            >
              {compiled.map((item) => (
                <Grid item xs={12} md={6} key={item.title}>
                  <div className="caseStudyItem">
                    <img src={item.img} width="100%" height="100%" />
                  </div>
                </Grid>
              ))}
            </Grid> */}
          </div>
        </div>
        <div className="section">
          <div className="soloCopy">
            <h1>Closeups</h1>
          </div>
          <div className="fullWidth">
            <Grid
              container
              rowSpacing={{ xs: 1, md: 2 }}
              columnSpacing={{ xs: 1, md: 2 }}
            >
              {closeups.map((item, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <div className="caseStudyItem">
                    <img
                      src={item.src}
                      srcSet={item.src}
                      alt={item.alt}
                      loading="lazy"
                      width={"100%"}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div className="section">
          <div className="soloCopy">
            <h1>The Letters</h1>
            <p className="paraCopy">
              I spent a lot of time on the construction of each letter form
              before bringing them into 3D. Try hovering over each letter to
              reveal the construction of each design.
            </p>
          </div>
          <div className="fullWidth">
            <Grid
              container
              rowSpacing={{ xs: 1, md: 2 }}
              columnSpacing={{ xs: 1, md: 2 }}
            >
              {mosaicImages.map((item, index) => (
                <Grid item xs={6} md={4} key={index}>
                  <div className="caseStudyItem">
                    <img
                      src={item.src}
                      srcSet={item.src}
                      alt={item.alt}
                      loading="lazy"
                      width={"100%"}
                      onClick={() => {
                        setCurrentIndex(index);
                        setOpen(true);
                      }}
                    />
                    <img
                      className="constructionOverlay"
                      src={constructionImages[index].src}
                      srcSet={constructionImages[index].src}
                      alt={item.alt}
                      loading="lazy"
                      width={"95%"}
                      onClick={() => {
                        setCurrentIndex(index);
                        setOpen(true);
                      }}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
            <Lightbox
              currentImageIndex={currentImageIndex}
              setCurrentIndex={setCurrentIndex}
              isOpen={isOpen}
              onClose={() => setOpen(false)}
              images={lightboxImages}
            />
          </div>
        </div>
        <div className="footer">
          <h3 style={{}}>© Sonny Hughes {new Date().getFullYear()}</h3>
          <div className="socialIcons">
            <a
              className="socialIcon instagram"
              href="https://www.instagram.com/snowshughes/"
              target="_blank"
            ></a>
            <a
              className="socialIcon twitter"
              href="https://twitter.com/SnowShughes"
              target="_blank"
            ></a>
            <a
              className="socialIcon vimeo"
              href="https://vimeo.com/snowshughes"
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
}

textBalancer.balanceText(".paraCopy, .heroCopy");

export default App;

{
  /* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/251406702?h=cf4797be1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Motion Reel"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
}
