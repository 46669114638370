import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App";
import Massless from "./Case Study Pages/Massless";
import UIDesign from "./Case Study Pages/ui-design";
import TwoDeeAnimation from "./Case Study Pages/2d-animation";
import ThirtySixDoT2017 from "./Case Study Pages/36DoT-2017";
import ThirtySixDoT2018 from "./Case Study Pages/36DoT-2018";
import ThirtySixDoT2021 from "./Case Study Pages/36DoT-2021";
import XenoJukeBox from "./Case Study Pages/xeno-jukebox";

import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Route, Router, Routes } from "react-router-dom";

import ScrollToTop from "./ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/">
          <Route index element={<App />} />
          <Route path="massless" element={<Massless />} />
          <Route path="ui-design" element={<UIDesign />} />
          <Route path="2d-animation" element={<TwoDeeAnimation />} />
          <Route path="36dot-2017" element={<ThirtySixDoT2017 />} />
          <Route path="36dot-2018" element={<ThirtySixDoT2018 />} />
          <Route path="36dot-2021" element={<ThirtySixDoT2021 />} />
          <Route path="xeno-jukebox" element={<XenoJukeBox />} />
        </Route>
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
