import "./App.css";
import React, { useState, useCallback, useEffect } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

import Masonry from "@mui/lab/Masonry";

import Lightbox from "./Components/Lightbox";

import NavBar from "./NavBar";

import { mosaicImages, lightboxImages } from "./portfolioImages";
import LazyLoad from "react-lazy-load";
import { useMediaQuery, useTheme } from "@mui/material";
// import { width } from "@mui/system";

//ImageKit is setup to mirror the public directory
export const imageKitURL = "https://ik.imagekit.io/hdej6o1vca/";

//This dynamically creates the Array but I have hardcoded it for loading reasons
// Original answer by Kind Kouprey, additional comments and integer index alternative by me.
function importAll(r) {
  //// Use these line if you like to access arrays using an integer index.
  let images = [];

  r.keys().map((item, index) => {
    const img = new Image();
    img.src = r(item);
    const filename = r(item).split("/").pop();
    const cleanFilename = filename.split(".").splice(0, 1);
    const ext = filename.split(".").splice(2, 1);
    img.onload = () => {
      images.push({
        src: imageKitURL + "OtherWork/" + cleanFilename + "." + ext,
        width: img.width,
        height: img.height,
        alt: "Alt",
      });
    };
  });

  ////Use these line if you want to access each image using the file name.
  //let images = {};
  //r.keys().map((item, index) => { images[item.replace('./','')] = r(item); });

  return images;
}

// const images = importAll(
//   require.context("../public/OtherWork", false, /\.(png|jpe?g|gif)$/)
// );

const caseStudyData = [
  {
    img: imageKitURL + "/Case Study Thumbs/massless.jpg",
    title: "Massless Branding",
    link: "/massless",
  },
  {
    img: imageKitURL + "/Case Study Thumbs/joto-ui.jpg",
    title: "UI Design",
    link: "/ui-design",
  },
  {
    img: imageKitURL + "/Case Study Thumbs/36DoT-2017.jpg",
    title: "36DoT - 2017",
    link: "/36dot-2017",
  },
  {
    img: imageKitURL + "/Case Study Thumbs/171021-FUI-07.gif",
    title: "2D Animation",
    link: "/2d-animation",
  },
  {
    img: imageKitURL + "/Case Study Thumbs/36DoT-2018.jpg",
    title: "36DoT - 2018",
    link: "/36dot-2018",
  },
  {
    img: imageKitURL + "/Case Study Thumbs/36DoT-2.jpg",
    title: "36DoT - 2021",
    link: "/36dot-2021",
  },
];

// const theme = createTheme({
//   appBackground: "#000000",
//   pageBackgroundColor: "#101010",
//   pageContentFontColor: "#e2e5ec",
//   pageContentLinkHoverColor: "aquamarine",
//   pageContentSelectionColor: "aquamarine",
//   headerNavFontColor: "#e2e5ec",
//   accentColor: "#1f1f1f",
// });

function App() {
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const gridLoader = Promise.all(
    mosaicImages.map(async (item, index) => (
      <div key={item.src}>
        <img
          className="portfolioImage"
          src={item.src}
          srcSet={item.src}
          alt={item.title}
          onClick={() => {
            setCurrentIndex(index);
            setOpen(true);
          }}
        />
      </div>
    ))
  );

  // console.log(images);

  const theme = useTheme();

  const smMatches = useMediaQuery(theme.breakpoints.up("sm"));
  const mdMatches = useMediaQuery(theme.breakpoints.up("md"));
  const lgMatches = useMediaQuery(theme.breakpoints.up("lg"));
  const xlMatches = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    // <ThemeProvider theme={theme}>
    <div className="App" style={{ overflowX: "hidden" }}>
      <NavBar />
      <div>
        <h1 style={{ margin: 0 }}>Hi, I'm Sonny & I am a Motion Designer.</h1>
        <h2>
          Click&nbsp;
          <a
            className="App-link"
            href="mailto:sonnyhughes@gmail.com?subject=Enquiry"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          &nbsp;if you'd like to get in touch
        </h2>
      </div>
      <div style={{ position: "relative", width: "100%", maxWidth: "1440px" }}>
        <div className="showreel">
          <ReactPlayer
            controls
            className="react-player"
            url="https://player.vimeo.com/video/251406702?h=cf4797be1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            width="100%"
            height="100%"
            // playing={playing}
          />
        </div>
      </div>
      <div className="caseStudies">
        <h1 style={{ paddingBottom: "20px" }}>Case Studies</h1>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {caseStudyData.map((item) => (
            <Grid item xs={12} md={6} key={item.title}>
              <Link to={item.link}>
                <div className="caseStudyItem">
                  <img
                    src={item.img}
                    width="100%"
                    height="100%"
                    loading="lazy"
                  />
                  <div className="overlay">{item.title}</div>
                </div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
      <div
        className="otherWork"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{ paddingBottom: "20px" }}>Other Work</h1>
        <Masonry
          columns={{ xs: 1, md: 2, lg: 3, xl: 4 }}
          spacing={{ xs: 1, md: 2, lg: 1, xl: 2 }}
          defaultHeight={500}
          defaultColumns={xlMatches ? 4 : lgMatches ? 3 : mdMatches ? 2 : 1}
          defaultSpacing={1}
          style={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          {mosaicImages.map((item, index) => (
            <div key={item.src}>
              <img
                id={index.toString()}
                className="portfolioImage"
                src={item.src}
                alt={item.title}
                onClick={() => {
                  setCurrentIndex(index);
                  setOpen(true);
                }}
              />
            </div>
          ))}
        </Masonry>
        <Lightbox
          currentImageIndex={currentImageIndex}
          setCurrentIndex={setCurrentIndex}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          images={lightboxImages}
        />
        {/* <Gallery style={{ width: "100%" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "33% 33% 33%",
              gridGap: 10,
            }}
          >
            {images.map((item) => (
              <Item
                original={item.src}
                thumbnail={item.src}
                width={item.width}
                height={item.height}
              >
                {({ ref, open }) => (
                  <img
                    style={smallItemStyles}
                    src={item.src}
                    srcSet={item.src}
                    ref={ref}
                    onClick={open}
                  />
                )}
              </Item>
            ))}
          </div>
        </Gallery> */}
      </div>
      <div className="footer">
        <h3 style={{}}>© Sonny Hughes {new Date().getFullYear()}</h3>
        <div className="socialIcons">
          <a
            className="socialIcon instagram"
            href="https://www.instagram.com/snowshughes/"
            target="_blank"
          ></a>
          <a
            className="socialIcon twitter"
            href="https://twitter.com/SnowShughes"
            target="_blank"
          ></a>
          <a
            className="socialIcon vimeo"
            href="https://vimeo.com/snowshughes"
            target="_blank"
          ></a>
        </div>
      </div>
    </div>
    // </ThemeProvider>
  );
}

export default App;

{
  /* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/251406702?h=cf4797be1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Motion Reel"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
}
