export const mosaicImages = [
  {
    src: "https://ik.imagekit.io/hdej6o1vca/UI/joto-ui.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/UI/171019-Trapcode-042.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/UI/CarbonMusic-Iphone-01.jpg",
    width: 1920,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/UI/171021-FUI-07.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/UI/HoneyChat-iPad-01.jpg",
    width: 1920,
    height: 1275,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/UI/HoneyChat-iPhone-01.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/UI/SalesDashboard-UI-01.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/UI/VidFuse-UI-03.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
];

export const lightboxImages = mosaicImages.map(
  ({ src, width, height, alt }) => ({
    src,
    width,
    height,
    alt,
  })
);
