import "../CaseStudy.css";
import React, { useState, useCallback, useEffect } from "react";
// import ReactPlayer from "react-player";
// import { styled } from "@mui/material/styles";
// import Grid from "@mui/material/Grid";
// import ImageList from "@mui/material/ImageList"; // Grid version 2
// import ImageListItem from "@mui/material/ImageListItem"; // Grid version 2
import Lightbox from "../Components/Lightbox";
import Masonry from "@mui/lab/Masonry";

import NavBar from "../NavBar";

// import "photoswipe/dist/photoswipe.css";
// import { Gallery, Item } from "react-photoswipe-gallery";

import textBalancer from "text-balancer";

import { imageKitURL } from "../App";

import { mosaicImages, lightboxImages } from "./ui_design_images";

import { useMediaQuery, useTheme } from "@mui/material";

function importAll(r) {
  //// Use these line if you like to access arrays using an integer index.
  let images = [];

  r.keys().map((item, index) => {
    const img = new Image();
    img.src = r(item);
    const filename = r(item).split("/").pop();
    const cleanFilename = filename.split(".").splice(0, 1);
    const ext = filename.split(".").splice(2, 1);
    img.onload = () => {
      images.push({
        src: imageKitURL + "UI/" + cleanFilename + "." + ext,
        width: img.width,
        height: img.height,
        alt: "Alt",
      });
    };
  });

  ////Use these line if you want to access each image using the file name.
  //let images = {};
  //r.keys().map((item, index) => { images[item.replace('./','')] = r(item); });

  return images;
}

// const images = importAll(
//   require.context("../../public/UI", false, /\.(png|jpe?g|gif)$/)
// );

function App() {
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const theme = useTheme();

  const smMatches = useMediaQuery(theme.breakpoints.up("sm"));
  const mdMatches = useMediaQuery(theme.breakpoints.up("md"));
  const lgMatches = useMediaQuery(theme.breakpoints.up("lg"));
  const xlMatches = useMediaQuery(theme.breakpoints.up("xl"));

  // console.log(images);
  return (
    <div className="App">
      <NavBar />
      <div className="pageWrapper">
        <div className="section">
          <div className="soloCopy">
            <h1 style={{ paddingBottom: "20px" }}>Various UI Designs</h1>
          </div>
          <div className="fullWidth">
            <Masonry
              columns={{ xs: 1, md: 2, lg: 3 }}
              spacing={{ xs: 1, md: 2, lg: 1 }}
              defaultHeight={500}
              defaultColumns={lgMatches ? 3 : mdMatches ? 2 : 1}
              defaultSpacing={1}
              style={{
                width: "100%",
                overflow: "hidden",
              }}
            >
              {mosaicImages.map((item, index) => (
                <div key={item.src}>
                  <img
                    className="portfolioImage"
                    src={item.src}
                    srcSet={item.src}
                    alt={item.title}
                    onClick={() => {
                      setCurrentIndex(index);
                      setOpen(true);
                    }}
                  />
                </div>
              ))}
            </Masonry>
            <Lightbox
              currentImageIndex={currentImageIndex}
              setCurrentIndex={setCurrentIndex}
              isOpen={isOpen}
              onClose={() => setOpen(false)}
              images={lightboxImages}
            />
          </div>
        </div>
        <div className="footer">
          <h3 style={{}}>© Sonny Hughes {new Date().getFullYear()}</h3>
          <div className="socialIcons">
            <a
              className="socialIcon instagram"
              href="https://www.instagram.com/snowshughes/"
              target="_blank"
            ></a>
            <a
              className="socialIcon twitter"
              href="https://twitter.com/SnowShughes"
              target="_blank"
            ></a>
            <a
              className="socialIcon vimeo"
              href="https://vimeo.com/snowshughes"
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
}

textBalancer.balanceText(".paraCopy, .heroCopy");

export default App;

{
  /* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/251406702?h=cf4797be1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Motion Reel"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
}
