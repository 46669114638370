import "../CaseStudy.css";
import React, { useState, useCallback, useEffect } from "react";
import ReactPlayer from "react-player";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList"; // Grid version 2
import ImageListItem from "@mui/material/ImageListItem"; // Grid version 2
import Lightbox from "../Components/Lightbox";

import NavBar from "../NavBar";

import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

import { imageKitURL } from "../App";

import { mosaicImages, lightboxImages } from "./2018_36DoT_Images";

import textBalancer from "text-balancer";

function importAll(r) {
  //// Use these line if you like to access arrays using an integer index.
  let images = [];

  r.keys().map((item, index) => {
    const img = new Image();
    img.src = r(item);
    const filename = r(item).split("/").pop();
    const cleanFilename = filename.split(".").splice(0, 1);
    const ext = filename.split(".").splice(2, 1);
    img.onload = () => {
      images.push({
        src: imageKitURL + "36Days 2018/" + cleanFilename + "." + ext,
        width: img.width,
        height: img.height,
        alt: "Alt",
      });
    };
  });

  ////Use these line if you want to access each image using the file name.
  //let images = {};
  //r.keys().map((item, index) => { images[item.replace('./','')] = r(item); });

  return images;
}

// const images = importAll(
//   require.context("../../public/36Days 2018", false, /\.(png|jpe?g|gif)$/)
// );

function App() {
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  // console.log(images);
  return (
    <div className="App">
      <NavBar />
      <div className="pageWrapper">
        <div className="section">
          <div className="soloCopy">
            <h1 style={{ paddingBottom: "20px" }}>36 Days of Type - 2018</h1>
          </div>
          <div className="fullWidth">
            <Grid
              style={{ display: "flex", placeContent: "center" }}
              container
              rowSpacing={{ xs: 1, md: 2 }}
              columnSpacing={{ xs: 1, md: 2 }}
            >
              {mosaicImages.map((item, index) => (
                <Grid item xs={6} md={3} key={item.title}>
                  <img
                    style={{ cursor: "pointer" }}
                    src={item.src}
                    srcSet={item.src}
                    alt={item.alt}
                    loading="lazy"
                    width={"100%"}
                    onClick={() => {
                      setCurrentIndex(index);
                      setOpen(true);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Lightbox
              currentImageIndex={currentImageIndex}
              setCurrentIndex={setCurrentIndex}
              isOpen={isOpen}
              onClose={() => setOpen(false)}
              images={lightboxImages}
            />
          </div>
        </div>
        <div className="footer">
          <h3 style={{}}>© Sonny Hughes {new Date().getFullYear()}</h3>
          <div className="socialIcons">
            <a
              className="socialIcon instagram"
              href="https://www.instagram.com/snowshughes/"
              target="_blank"
            ></a>
            <a
              className="socialIcon twitter"
              href="https://twitter.com/SnowShughes"
              target="_blank"
            ></a>
            <a
              className="socialIcon vimeo"
              href="https://vimeo.com/snowshughes"
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
}

textBalancer.balanceText(".paraCopy, .heroCopy");

export default App;

{
  /* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/251406702?h=cf4797be1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Motion Reel"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
}
