import "./App.css";

import { Link } from "react-router-dom";

function NavBar() {
  return (
    <header className="App-header">
      <Link to="/">
        <div className="logo">
          <img src="./shLogo.png" alt="logo" />
        </div>
      </Link>
    </header>
  );
}

export default NavBar;
