export const mosaicImages = [
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/King-Cow.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/Change-Conference.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/171021-FUI-072.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/171122-47ink-TrickyTransitions-032.gif",
    width: 540,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/47ink-Logo-Anim2.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/DNA2.gif",
    width: 540,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/Infitex-Fashion2.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/Infitex-Finger-Press2.gif",
    width: 825,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/Infitex-IoT2.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/Joto-Hand-Icon2.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/Joto-Intro2.gif",
    width: 540,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/Lightz-Intro2.gif",
    width: 540,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/MGD-Shapes-012.gif",
    width: 540,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/2D Animation/Paranoia2.gif",
    width: 960,
    height: 540,
    alt: "Alt",
  },
];

export const lightboxImages = mosaicImages.map(
  ({ src, width, height, alt }) => ({
    src,
    width,
    height,
    alt,
  })
);
