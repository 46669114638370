export const mosaicImages = [
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/001-Deity.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/002-RecursiveFracture.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/003-RecursiveFracture.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/002-RecursiveSlats.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/002-Anenome.jpg",
    width: 2500,
    height: 1406,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/002-TruchetTiles.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/CustomCurl.jpg",
    width: 1440,
    height: 1800,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/005-BrandDNA.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/006-SeaOfHoles.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/007-Ripple.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/ArchViz-Exterior.jpg",
    width: 1080,
    height: 1200,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/ArchViz-Interior.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Ate.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  // {
  //   src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Cagey.jpg",
  //   width: 1080,
  //   height: 1080,
  //   alt: "Alt",
  // },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/004-ShadowOfTheBeast.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Amoeboid.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/DofDust.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Eggage.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Fractured-X.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/CrystalCove.jpeg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/HexTunnel.jpg",
    width: 2500,
    height: 1406,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Corridor6.gif",
    width: 540,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/DanceMagic.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/HyperHex.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Execute.png",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Extra.jpg",
    width: 1440,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Fraxtal.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Grippage.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/HardSurface.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/MarsBaseAlpha.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/AlienWalkway-Frames-03.gif",
    width: 720,
    height: 405,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/IceTea.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/FractalPlay.png",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Nefertiti-02.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Noir.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/MeadowBalls.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/HypnoBubble.gif",
    width: 700,
    height: 700,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/MeetMeAtTheStation.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/MetalHead.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Old-Timey-Type.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/OrangeBuds.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/P-Green.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Ogle.gif",
    width: 540,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Cthulu.gif",
    width: 800,
    height: 600,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/PositionalNanoassembly.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/PhantomTollbooth.png",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/MantaRay.gif",
    width: 800,
    height: 600,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/React.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Pris.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/SlicedBread.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Smog.jpg",
    width: 2500,
    height: 1406,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/RecursiveExtrude.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Snek.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/StairwayToHeaven.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/StruggleGum.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/SurfaceTension.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/ReactionDiffusion.gif",
    width: 720,
    height: 720,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/UnderPressure.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Valley.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Vines.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/They-Live.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/TruchetTiles.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/TruchetTriangles.jpg",
    width: 1920,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Ultraviolet.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Whatever-Wall.gif",
    width: 540,
    height: 540,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/XenoJukebox.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Z-Alien-Os.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Ying.png",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/PortfolioImages/Wobble.gif",
    width: 700,
    height: 700,
    alt: "Alt",
  },
];

export const lightboxImages = mosaicImages.map(
  ({ src, width, height, alt }) => ({
    src,
    width,
    height,
    alt,
  })
);
