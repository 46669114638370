export const mosaicImages = [
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-A2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-B2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-C2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-D2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-E2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-F2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-G2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-H2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-I2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-J2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-K2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-L2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-M2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-N2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-O2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-P2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-Q2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-R2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-S2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-T2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-U2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-V2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-W2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-X2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-Y2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/170220-36Days-Z2.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-001.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-002.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-003.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-004.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-005.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-006.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-007.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-008.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-009.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2017/Letters/Z-170220-36Days-010.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
];

export const lightboxImages = mosaicImages.map(
  ({ src, width, height, alt }) => ({
    src,
    width,
    height,
    alt,
  })
);
