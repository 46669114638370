import "../CaseStudy.css";
import React from "react";
import ReactPlayer from "react-player";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList"; // Grid version 2
import ImageListItem from "@mui/material/ImageListItem"; // Grid version 2

import NavBar from "../NavBar";

import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

import textBalancer from "text-balancer";

function importAll(r) {
  //// Use these line if you like to access arrays using an integer index.
  let images = [];
  r.keys().map((item, index) => {
    images.push(r(item));
  });

  ////Use these line if you want to access each image using the file name.
  //let images = {};
  //r.keys().map((item, index) => { images[item.replace('./','')] = r(item); });

  return images;
}

const images = importAll(
  require.context("../../public/Xeno Jukebox", false, /\.(png|jpe?g|gif)$/)
);

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="pageWrapper">
        <div className="section">
          <div className="soloCopy">
            <h1>Xenomorphic Jukebox</h1>
            <p className="paraCopy">
              A local electronic musician approached me to make some cover art
              for an EP. Here are a few of the final concepts.
            </p>
          </div>
          <div className="fullWidth">
            <ImageList cols={2} gap={8}>
              {images.map((item, index) => (
                <ImageListItem className="imageListItem" key={index}>
                  <img
                    src={`${images[index]}?w=248&fit=crop&auto=format`}
                    srcSet={`${images[index]}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </div>
        <div className="footer">
          <h3 style={{}}>© Sonny Hughes {new Date().getFullYear()}</h3>
          <div className="socialIcons">
            <a
              className="socialIcon instagram"
              href="https://www.instagram.com/snowshughes/"
              target="_blank"
            ></a>
            <a
              className="socialIcon twitter"
              href="https://twitter.com/SnowShughes"
              target="_blank"
            ></a>
            <a
              className="socialIcon vimeo"
              href="https://vimeo.com/snowshughes"
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
}

textBalancer.balanceText(".paraCopy, .heroCopy");

export default App;

{
  /* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/251406702?h=cf4797be1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Motion Reel"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
}
