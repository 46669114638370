import "../CaseStudy.css";
import React from "react";
import ReactPlayer from "react-player";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList"; // Grid version 2
import ImageListItem from "@mui/material/ImageListItem"; // Grid version 2

import NavBar from "../NavBar";

import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

import textBalancer from "text-balancer";

import { imageKitURL } from "../App";

const threeDeeLogos = [
  {
    img: imageKitURL + "Massless Case Study/3d-logo.png",
    title: "1",
  },
  {
    img: imageKitURL + "Massless Case Study/3d-film-logo.png",
    title: "2",
  },
  {
    img: imageKitURL + "Massless Case Study/3d-wood-logo.png",
    title: "3",
  },
  {
    img: imageKitURL + "Massless Case Study/3d-tyre-logo.jpg",
    title: "4",
  },
];

const webDesign = [
  {
    img: imageKitURL + "Massless Case Study/website-mockup-tablet.jpg",
    title: "1",
  },
  {
    img: imageKitURL + "Massless Case Study/website-mockup.jpg",
    title: "2",
  },
];

const packaging = [
  {
    img: imageKitURL + "Massless Case Study/Packaging-Mockup.jpg",
    title: "1",
  },
  {
    img: imageKitURL + "Massless Case Study/packaging-exploded.png",
    title: "2",
  },
];

const product = [
  {
    img: imageKitURL + "Massless Case Study/tracker-assembled.jpg",
    title: "1",
  },
  {
    img: imageKitURL + "Massless Case Study/tracker-contents.jpg",
    title: "2",
  },
  {
    img: imageKitURL + "Massless Case Study/2018-pen.jpg",
    title: "3",
  },
  {
    img: imageKitURL + "Massless Case Study/2019-pen.jpg",
    title: "4",
  },
];

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="pageWrapper">
        <div className="section">
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={9} key={1} order={{ xs: 2, md: 1 }}>
              <img
                src={imageKitURL + "Massless Case Study/Massless-Pen-Hero.jpg"}
                width="100%"
              />
            </Grid>
            <Grid item xs={12} md={3} key={2} order={{ xs: 1, md: 2 }}>
              <div className="heroCopy">
                <h1 className="hero">Massless Case Study</h1>
                <p>
                  Massless is a technology startup focusing on creating a new
                  breed of spatial computing input devices. Our first product is
                  the Massless Pen, a 3D stylus for use in design and
                  engineering applications inside of VR.
                </p>
                <p>
                  As the Design Lead I have taken on many responsibilities. This
                  page details some of the content and materials that I have
                  created for Massless.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="section">
          <div className="soloCopy">
            <h1>Branding</h1>
            <p className="paraCopy">
              I developed the new "Helix" brand identity for the company along
              with accompanying brand guidelines. The logo is constructed from
              two sine waves, which are often used to describe the way in which
              light propagates. This ties in with the origin of the companies
              name which derives from the fact that light has no mass.
            </p>
            <p className="paraCopy">
              The helix itself is also an example of a complex three dimensional
              shape that can be achieved through the use of our Pen. I also
              created a custom typeface for the word mark.
            </p>
          </div>
          <div className="fullWidth">
            <img
              src={imageKitURL + "Massless Case Study/logo-breakdown.jpg"}
              width="100%"
            />
          </div>
        </div>
        <div className="section">
          <div className="soloCopy">
            <h1>3D Logo’s</h1>
            <p className="paraCopy">
              Various 3D versions of the logo were created to show the diversity
              of our customers as well as the playful side of the brand.
            </p>
          </div>
          <div className="fullWidth">
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {threeDeeLogos.map((item) => (
                <Grid item xs={12} md={6} key={item.title}>
                  <div className="caseStudyItem">
                    <img
                      src={item.img}
                      width="100%"
                      height="100%"
                      loading="lazy"
                    />
                    {/* <div className="overlay">{item.title}</div> */}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div className="section">
          <div className="soloCopy">
            <h1>Website Design</h1>
            <p className="paraCopy">
              I also designed and built a web site from scratch inside of
              Wordpress. The site was designed to detail the various features of
              the device, whilst showing off the Pen itself.
            </p>
          </div>
          <div className="fullWidth">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {webDesign.map((item) => (
                <Grid item xs={12} md={6} key={item.title}>
                  <div className="caseStudyItem">
                    <img src={item.img} width="100%" height="100%" />
                    {/* <div className="overlay">{item.title}</div> */}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div className="section">
          <div className="soloCopy">
            <h1>Packaging</h1>
            <p className="paraCopy">
              Using the 3D models of the pen and bundled accessories, I created
              the internal foam layout for the packaging and designed the
              artwork to wrap around the box. The Packaging was very well
              received by the rest of the team and customers alike.
            </p>
          </div>
          <div className="fullWidth">
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {packaging.map((item) => (
                <Grid item xs={12} md={6} key={item.title}>
                  <div className="caseStudyItem">
                    <img src={item.img} width="100%" height="100%" />
                    {/* <div className="overlay">{item.title}</div> */}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div className="section">
          <div className="soloCopy">
            <h1>Product Visualisations</h1>
            <p className="paraCopy">
              I also assisted the team by creating numerous high quality,
              photo-realistic renders of the products.
            </p>
          </div>
          <div className="fullWidth">
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {product.map((item) => (
                <Grid item xs={12} md={6} key={item.title}>
                  <div className="caseStudyItem">
                    <img src={item.img} width="100%" height="100%" />
                    {/* <div className="overlay">{item.title}</div> */}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div className="footer">
          <h3 style={{}}>© Sonny Hughes {new Date().getFullYear()}</h3>
          <div className="socialIcons">
            <a
              className="socialIcon instagram"
              href="https://www.instagram.com/snowshughes/"
              target="_blank"
            ></a>
            <a
              className="socialIcon twitter"
              href="https://twitter.com/SnowShughes"
              target="_blank"
            ></a>
            <a
              className="socialIcon vimeo"
              href="https://vimeo.com/snowshughes"
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
}

textBalancer.balanceText(".paraCopy, .heroCopy");

export default App;

{
  /* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/251406702?h=cf4797be1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Motion Reel"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
}
