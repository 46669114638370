import "../CaseStudy.css";
import React, { useState, useCallback, useEffect } from "react";
import ReactPlayer from "react-player";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList"; // Grid version 2
import ImageListItem from "@mui/material/ImageListItem"; // Grid version 2
import Lightbox from "../Components/Lightbox";

import NavBar from "../NavBar";

import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

import textBalancer from "text-balancer";

import { imageKitURL } from "../App";

import { mosaicImages, lightboxImages } from "./2017_36DoT_Images";

function importAll(r) {
  //// Use these line if you like to access arrays using an integer index.
  let images = [];

  r.keys().map((item, index) => {
    const img = new Image();
    img.src = r(item);
    const filename = r(item).split("/").pop();
    const cleanFilename = filename.split(".").splice(0, 1);
    const ext = filename.split(".").splice(2, 1);
    img.onload = () => {
      images.push({
        src: imageKitURL + "36Days 2017/Letters/" + cleanFilename + "." + ext,
        width: img.width,
        height: img.height,
        alt: "Alt",
      });
    };
  });

  ////Use these line if you want to access each image using the file name.
  //let images = {};
  //r.keys().map((item, index) => { images[item.replace('./','')] = r(item); });

  return images;
}

// const images = importAll(
//   require.context(
//     "../../public/36Days 2017/Letters",
//     false,
//     /\.(png|jpe?g|gif)$/
//   )
// );

const compiled = [
  {
    img: imageKitURL + "36Days 2017/170421-36Days-All-022.jpg",
    title: "1",
  },
  {
    img: imageKitURL + "36Days 2017/170421-36Days-All-01.jpg",
    title: "2",
  },
];

function App() {
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  // console.log(images);
  return (
    <div className="App">
      <NavBar />
      <div className="pageWrapper">
        <div className="section">
          <div className="soloCopy">
            <h1 style={{ paddingBottom: "20px" }}>36 Days of Type - 2017</h1>
            <p className="paraCopy">
              For this series I set up a simple grid system and set of rules
              from the outset. Each letter was to be made from a single unbroken
              line and rendered on the same start black background.
            </p>
            <p className="paraCopy">
              I really liked the aesthetic that this produced and it made for a
              very intriguing font once the entire set was complete.
            </p>
          </div>
          <div className="fullWidth">
            <Grid
              container
              rowSpacing={{ xs: 1, md: 2 }}
              columnSpacing={{ xs: 1, md: 2 }}
            >
              {compiled.map((item) => (
                <Grid item xs={12} md={6} key={item.title}>
                  <div className="caseStudyItem">
                    <img src={item.img} width="100%" height="100%" />
                    {/* <div className="overlay">{item.title}</div> */}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div className="section">
          <div className="soloCopy">
            <h1>The Letters</h1>
          </div>
          <div className="fullWidth">
            <Grid
              container
              rowSpacing={{ xs: 1, md: 2 }}
              columnSpacing={{ xs: 1, md: 2 }}
            >
              {mosaicImages.map((item, index) => (
                <Grid item xs={6} md={3} key={item.title}>
                  <img
                    style={{ cursor: "pointer" }}
                    src={item.src}
                    srcSet={item.src}
                    alt={item.alt}
                    loading="lazy"
                    width={"100%"}
                    onClick={() => {
                      setCurrentIndex(index);
                      setOpen(true);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Lightbox
              currentImageIndex={currentImageIndex}
              setCurrentIndex={setCurrentIndex}
              isOpen={isOpen}
              onClose={() => setOpen(false)}
              images={lightboxImages}
            />
          </div>
        </div>
        <div className="footer">
          <h3 style={{}}>© Sonny Hughes {new Date().getFullYear()}</h3>
          <div className="socialIcons">
            <a
              className="socialIcon instagram"
              href="https://www.instagram.com/snowshughes/"
              target="_blank"
            ></a>
            <a
              className="socialIcon twitter"
              href="https://twitter.com/SnowShughes"
              target="_blank"
            ></a>
            <a
              className="socialIcon vimeo"
              href="https://vimeo.com/snowshughes"
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
}

textBalancer.balanceText(".paraCopy, .heroCopy");

export default App;

{
  /* <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/251406702?h=cf4797be1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Motion Reel"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
}
