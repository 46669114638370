export const mosaicImages = [
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/A-04-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/B-02-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/C-02-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/D-02-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/E-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/F-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/G-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/H-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/I-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/J-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/K-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/L-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/M-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/N-02-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/O-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/P-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Q-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/R-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/S-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/T-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/U-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/V-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/W-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/X-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Y-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-02-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-1-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-2-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-3-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-4-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-5-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-6-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-7-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-8-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Letters/Z-9-01-W.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
];

export const lightboxImages = mosaicImages.map(
  ({ src, width, height, alt }) => ({
    src,
    width,
    height,
    alt,
  })
);
