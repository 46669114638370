export const mosaicImages = [
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180324-36DaysofType-A-032.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180324-36DaysofType-B-032.jpg",
    width: 1080,
    height: 1080,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180324-36DaysofType-C-01B-E2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180404-36DaysofType-D-05-E2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180405-36DaysofType-E-04B-AE2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180406-36DaysofType-F.gif",
    width: 720,
    height: 720,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180408-36DaysofType-G-03C2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180409-36DaysofType-H-01_Strawberry-E-022.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180410-36DaysofType-I.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180411-36DaysofType-J.gif",
    width: 720,
    height: 720,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180412-36DaysofType-K.gif",
    width: 720,
    height: 720,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180413-36DaysofType-L-01B-E2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180415-36DaysofType-M-01-E-032.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },

  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180415-36DaysofType-N-01B-E-022.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180416-36DaysofType-O.gif",
    width: 720,
    height: 720,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180417-36DaysofType-P-01_E_A2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180418-36DaysofType-Q-01_E_22.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180420-36DaysofType-R-01-B2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180421-36DaysofType-S-010003_00032.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180421A-36DaysofType-T.gif",
    width: 720,
    height: 720,
    alt: "Alt",
  },

  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180422-36DaysofType-U2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180423-36DaysofType-V-01-E-A2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180424-36DaysofType-W-01-A-E-Darker2.jpg",
    width: 750,
    height: 750,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180425-36DaysofType-X.gif",
    width: 720,
    height: 720,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180426-36DaysofType-Y-02-E-B2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2018/180427-36DaysofType-Z-02-E-B2.jpg",
    width: 1280,
    height: 1280,
    alt: "Alt",
  },
];

export const lightboxImages = mosaicImages.map(
  ({ src, width, height, alt }) => ({
    src,
    width,
    height,
    alt,
  })
);
