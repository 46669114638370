export const constructionImages = [
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-a.png",
    width: 2161,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-b.png",
    width: 2161,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-c.png",
    width: 2161,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-d.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-e.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-f.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-g.png",
    width: 2160,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-h.png",
    width: 2160,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-i.png",
    width: 2160,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-j.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-k.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-l.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-m.png",
    width: 2160,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-n.png",
    width: 2160,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-o.png",
    width: 2160,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-p.png",
    width: 2160,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-q.png",
    width: 2160,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-r.png",
    width: 2160,
    height: 2161,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-s.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-t.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-u.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-v.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-w.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-x.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-y.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/construction-z.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-0.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-1.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-2.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-3.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-4.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-5.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-6.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-7.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-8.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
  {
    src: "https://ik.imagekit.io/hdej6o1vca/36Days 2021/Construction/z-construction-9.png",
    width: 2160,
    height: 2160,
    alt: "Alt",
  },
];

export const lightboxImages = constructionImages.map(
  ({ src, width, height, alt }) => ({
    src,
    width,
    height,
    alt,
  })
);
